import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';

class Soft_Box_Tab extends Component  {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'image'

        return <div>
                <div className="sbs-provide-security pd-top-120"  >
                    <div className="container" >
                        <div className="row justify-content-center" >
                            <div className="col-xl-6 col-lg-6 offset-xl-1 order-lg-12 wow animated fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
                                <div className="img-with-video">
                                    <div className="img-wrap" >
                                        <img src={publicUrl+sectiondata.softboxtab.imgurl} style={{border: '1px solid', borderRadius:125}} alt={imgattr} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6 order-lg-1 align-self-center wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.3s" >
                                <div className="section-title style-two" style={{backgroundImage: 'url(assets/img/bg/10.png)'}} >
                                <h2 className="title"><span>{sectiondata.softboxtab.sectiontitle}</span> {sectiondata.softboxtab.sectiontitle_color}</h2>
                                <p>Perfect Handling & Medical Environment was founded in December 2010. With an accumulated experience of over 30 years in biology and pharmaceutical sector, this leader's main character trait: perseverance. Specializing in the import and distribution of chemicals, laboratory materials and consumables, PH Diagnostics is a human-sized company and always will be at your disposal to respond, individually and with great care, to all your technical and commercial queries.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    }
}

export default Soft_Box_Tab