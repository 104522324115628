import React, { Component } from 'react';
import sectiondata from '../../data/sections.json';
import parse from 'html-react-parser';

class Video extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'image'
        let data = sectiondata.video
        
    return <div>
            <div className="sbs-provide-security pd-top-120">
                <div className="container">
                    <div className="row justify-content-center" >
                        <div className="col-xl-6 col-lg-6 order-lg-6 wow animated fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s" style={{flex:1,alignItems:'center',alignContent:'center'}}>
                            <div className="img-with-video">
                                <div className="img-wrap">
                                    <img src={publicUrl + data.image_url} style={{borderRadius:30}} alt={imgattr}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 order-lg-1 align-self-center wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.3s">
                            <div className="section-title style-two">
                                <h2 className="title">{parse(data.title)} <span>{data.title_color}</span></h2>
                                <p>Building profitable markets in partnership with global healthcare brands across the North Africa and MENA region. <br/>
                                PH Diagnostics is committed to working with healthcare  across the North Africa and MENA region to deliver innovative and technologically advanced diagnostic solutions that help to improve people's health. <br/>
                                The company is focused on marketing a portfolio of leading global healthcare brands and best-in-class diagnostic products in key areas : Molecular diagnostics, personalized medicine, immunodiagnostics and medical equipment.
 </p>
                            </div>
                            <div className="row">
                            {data.list.map( ( item, i ) => 
                                <div key={i} className="col-md-12">
                                    <div className="riyaqas-check-list">
                                        <img className="media-left" src={publicUrl+item.icon} alt={imgattr}/>
                                        <span>{item.title}</span>
                                    </div>
                                </div>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Video