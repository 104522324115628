import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Navbar extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'logo'
        let anchor = '#'
        return (
            <div>
                <nav className="navbar navbar-area navbar-expand-lg nav-style-01" style={{backgroundColor:'white'}}>
                    <div className="container nav-container">
                        <div className="responsive-mobile-menu">
                            <div className="logo-wrapper mobile-logo">
                                <a href={anchor} className="logo">
                                    <img src={publicUrl+ 'assets/img/logo.png' } style={{width:100, height:50}}alt={imgattr} />
                                </a>
                            </div>
                            
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#Riyaqas_main_menu" 
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggle-icon">
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                </span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="Riyaqas_main_menu">
                            <div className="logo-wrapper desktop-logo">
                                <a href="#" className="logo">
                                    <img src={publicUrl + 'assets/img/logo.png'} style={{width:220, height:80, marginLeft:'45%'}} alt={imgattr}/>
                                </a> 
                            </div>
                            <ul className="navbar-nav" style={{marginLeft:'20%'}}>
                                <li >
                                    <Link to={'/'}>Home</Link>
                                </li>
                                 <li>
                                    <Link to="/about">About Us</Link>
                      
                                </li>
                                <li>
                                    <Link to="/our-partners">Our Partners</Link>
                                </li>
                                <li>
                                    <Link to="/comming-soon" >Coming Soon</Link>
                                   
                                </li>
                                <li>
                                    <Link to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}


export default Navbar